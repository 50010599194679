import React, { useState, useEffect, useRef } from 'react';
import './aydergicarousel.css';

const AyDergiCarouselMobile = () => {

    // const AkilliYasamIssues = [
    //     {
    //         title: '162. Sayı (Ekim 2024)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi162.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=162'
    //     },
    //     {
    //         title: '161. Sayı (Eylül 2024)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi161.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=161'
    //     },
    //     {
    //         title: '160. Sayı (Ağustos 2024)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi160.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=160'
    //     },
    //     {
    //         title: '159. Sayı (Temmuz 2024)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi159.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=159'
    //     },
    //     {
    //         title: '158. Sayı (Haziran 2024)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi158.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=158'
    //     },
    //     {
    //         title: '157. Sayı (Mayıs 2024)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi157.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=157'
    //     },
    //     {
    //         title: '156. Sayı (Nisan 2024)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi156.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=156'
    //     },
    //     {
    //         title: '155. Sayı (Mart 2024)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi155.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=155'
    //     },
    //     {
    //         title: '154. Sayı (Şubat 2024)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi154.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=154'
    //     },
    //     {
    //         title: '153. Sayı (Ocak 2024)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi153.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=153'
    //     },
    //     {
    //         title: '152. Sayı (Aralık 2023)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi152.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=152'
    //     },
    //     {
    //         title: '151. Sayı (Kasım 2023)',
    //         image: 'https://www.akilliyasamdergisi.com/images/sayi151.jpg',
    //         link: 'https://www.akilliyasamdergisi.com/flipbook/?id=151'
    //     }
    // ];

    const [startIndex, setStartIndex] = useState(0);
    const carouselRef = useRef(null);
    const [magazineList, setMagazineList] = useState([]);

    useEffect(() => {
        const fetchMagazineData = async () => {
          try {
            const response = await fetch("https://api.sigortagundem.com/api/magazine");
            const data = await response.json();
      
            const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      
            const limitedData = sortedData.slice(0, 10);
      
            setMagazineList(limitedData);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
      
        fetchMagazineData();
      }, []);

    const handleNext = () => {
        setStartIndex((prevIndex) => (prevIndex < 9 ? prevIndex + 1 : 0));
    };

    const handlePrev = () => {
        setStartIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : magazineList.length - 1));
    };

    useEffect(() => {
        const carousel = carouselRef.current;

        if (carousel) {
            const itemWidth = carousel.clientWidth / 1;
            carousel.style.transition = 'transform 0.5s ease';
            carousel.style.transform = `translateX(-${startIndex * itemWidth}px)`;
        }
    }, [startIndex, magazineList.length]);

    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 8000); // 5 saniyede bir geçiş

        return () => clearInterval(interval); 
    }, []);

    return (
        <div className="carousel ay-dergi-carousel mt-2">
            <button className='ay-dergi-carousel-button' onClick={handlePrev}>
                <span class="material-symbols-outlined">
chevron_left
</span>
            </button>
            <div className="ay-dergi-carousel-inner-wrapper">
                <div
                    className="ay-dergi-carousel-inner"
                    ref={carouselRef}
                >
                    {magazineList.map((item, index) => (
                        <div
                            className="carousel-item ay-dergi-carousel-item"
                            key={index}
                            style={{ backgroundImage: `url(${item.cover})` }}
                        >
                            <a href={item.link} target='_blank' className="ay-dergi-carousel-item-link">
                                {/* Link content can be added here */}
                            </a>
                            <h3 className="ay-dergi-carousel-item-title">{item.title}</h3>
                        </div>
                    ))}
                </div>
            </div>
            <button className='ay-dergi-carousel-button' onClick={handleNext}>
                <span class="material-symbols-outlined">
chevron_right
</span>
            </button>
        </div>
    );
};

export default AyDergiCarouselMobile;
