import React, { useEffect, useState, useRef } from "react";
import "./left.css";

const AdContainer = ({ ad, onAdClick }) => {
  const adRef = useRef(null);

  useEffect(() => {
    adRef.current.innerHTML = ""; // Önceki içeriği temizle

    if (
      ad.imageURL.includes("doubleclick.net") ||
      ad.imageURL.includes("sadbundle")
    ) {
      const iframe = document.createElement("iframe");
      iframe.src = ad.imageURL;
      iframe.width = "160px";
      iframe.height = "650px";
      iframe.frameBorder = "0";
      iframe.scrolling = "no";
      iframe.style = "border: 0px; vertical-align: bottom;";
      if (ad.imageURL.includes("sadbundle")) {
        iframe.allowFullscreen = true;
      }
      adRef.current.appendChild(iframe);
    } else {
      const img = document.createElement("img");
      img.src = ad.imageURL;
      img.alt = ad.title;
      img.style = "width: 100%; height: 100%; object-fit: contain;";
      adRef.current.appendChild(img);
    }
  }, [ad]);

  return (
    <a
      href={ad.link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onAdClick}
    >
      <div ref={adRef} className="ad-container">
        {/* Reklam içeriği buraya dinamik olarak eklenecek */}
      </div>
    </a>
  );
};

const AdsLeft = () => {
  const [ad, setAd] = useState(null);

  useEffect(() => {
    // API'den reklam verilerini çek
    fetch("https://api.sigortagundem.com/api/ads")
      .then((response) => response.json())
      .then((data) => {
        const verticalAd = data.find(
          (ad) => ad.title.includes("Sol DİKEY") && ad.isActive === true
        );
        setAd(verticalAd);
      })
      .catch((error) => {
        console.error("Reklam verileri çekilirken hata oluştu:", error);
      });
  }, []);

  const handleAdClick = async () => {
    if (ad && ad._id) {
      try {
        await fetch(`https://api.sigortagundem.com/api/ads/${ad._id}/hit`, {
          method: "POST",
        });
      } catch (error) {
        console.error("Hit sayısı artırılırken hata oluştu:", error);
      }
    }
  };

  if (!ad) {
    return null; // Veya bir yükleme göstergesi
  }

  return (
    <div className="vertical-fixed-left adss">
      <AdContainer ad={ad} onAdClick={handleAdClick} />
    </div>
  );
};

export default AdsLeft;