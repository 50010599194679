import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import "./sgcarousel.css";
import SpecialNewsCard from "./SpecialNewsCard";
import SgBottomCarousel from "./SgBottomCarousel";
import SgBottomCarouselMobile from "./SgBottomCarouselMobile";
import LiveStream from "../liveStream/LiveStream";

const API_BASE_URL = "https://api.sigortagundem.com";

const SgCarousel = () => {
  const [topCarouselNews, setTopCarouselNews] = useState([]);
  const [pinnedNews, setPinnedNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const [newsResponse, pinnedResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/full-width-headlines`),
          axios.get(`${API_BASE_URL}/sabitlenmis`),
        ]);
  
        // console.log("Haber Verileri:", newsResponse.data); 
        // console.log("Sabitlenmiş Haber Verileri:", pinnedResponse.data); 
  
        let fetchedNews = [];
        if (Array.isArray(newsResponse.data.news)) {
          fetchedNews = newsResponse.data.news.filter(
            (news) => !news.isDraft
          );
        } else {
          console.error("API'den beklenen veri formatı bir dizi değil.");
        }
  
        if (Array.isArray(pinnedResponse.data)) {
          setPinnedNews(pinnedResponse.data); 
        }
  
        const combinedSlides = Array(15).fill(null); 
        const usedPositions = new Set();
        const addedNews = new Set(); 
  
       
        pinnedResponse.data.forEach((news) => {
          if (
            news.sabitlenmisSlidePosition !== null &&
            news.sabitlenmisSlidePosition >= 1 &&
            news.sabitlenmisSlidePosition <= 15
          ) {
            const position = news.sabitlenmisSlidePosition - 1;
            combinedSlides[position] = news;
            usedPositions.add(position);
            addedNews.add(news._id); 
          }
        });
  
        let i = 0;
  
        
        fetchedNews.forEach((news) => {
          if (addedNews.has(news._id)) return; 
          
          
          while (i < combinedSlides.length && (combinedSlides[i] !== null || usedPositions.has(i))) {
            i++;
          }
  
         
          if (i < combinedSlides.length) {
            combinedSlides[i] = news;
            addedNews.add(news._id); 
            i++; 
          }
        });
  
        // console.log("Carousel için Kombine Edilmiş Slide Verileri:", combinedSlides);
        setTopCarouselNews(combinedSlides); 
      } catch (error) {
        console.error("Veriler alınırken bir hata oluştu:", error);
      } finally {
        setLoading(false); 
      }
    };
  
    fetchNews();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % topCarouselNews.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [topCarouselNews.length]);

  const goToPrevious = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + topCarouselNews.length) % topCarouselNews.length
    );
  }, [topCarouselNews.length]);

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % topCarouselNews.length);
  }, [topCarouselNews.length]);

  const handleNewsClick = useCallback(async (newsId, slug, event) => {
    event.preventDefault();
    try {
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);
      window.location.href = `/${slug}`; 
    } catch (error) {
      console.error("Error incrementing view count:", error);
      window.location.href = `/${slug}`; 
    }
  }, []);
  

  const getNewsTag = useCallback((slide) => {
    if (slide.isFlashNewsTag) return "Flaş Haber";
    if (slide.isBreakingNewsTag) return "Son Dakika";
    if (slide.isExclusiveNewsTag) return "Özel Haber";
    if (slide.isAttentionNewsTag) return "Bu Habere Dikkat";
    return null;
  }, []);

  const carouselItems = useMemo(() => {
    return topCarouselNews
    .filter((slide) => slide !== null && slide !== undefined) 
    .map((slide, index) => (

      <div
        key={slide._id}
        onClick={(e) => handleNewsClick(slide._id, slide.slug, e)}
        className={`sg-top-carousel-item cursor-pointer ${
          index === currentIndex ? "active" : ""
        }`}
        style={{
          backgroundImage: `url(${slide.image || 'default-image-path.png'})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          flexShrink: "0",
          width: "100%",
          position: "relative",
        }}
      >
        <div className="sg-top-carousel-caption">
          <h5>{slide.title}</h5>
        </div>
        {getNewsTag(slide) && (
          <div
            className="slide-title"
            
          >
            {getNewsTag(slide)}
          </div>
        )}
      </div>
    ));
  }, [topCarouselNews, currentIndex, handleNewsClick, getNewsTag]);

  if (loading) {
    // return <div>Yükleniyor...</div>;
  }

  if (!topCarouselNews.length) {
    // return <div>Haber bulunamadı.</div>;
  }

  return (
    <div className="sg-carousel-container lg:mx-[550px]">
      <LiveStream />
      <div className="sg-carousel-top-container">
        <SgBottomCarousel />
      </div>
      <div className="hidden sg-carousel-top-mobile-container">
        <SgBottomCarouselMobile />
      </div>
      <div className="sg-carousel-bottom-container lg:grid grid-cols-3 gap-4 mt-4">
        <div className="sg-carousel-bottom-left-container col-span-2 relative overflow-hidden">
          <div
            className="sg-carousel-inner"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
              display: "flex",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            {carouselItems}
          </div>
          
          <button className="carousel-control-prev" onClick={goToPrevious}>
          <span class="material-symbols-outlined">arrow_back_ios
</span>
          </button>
          <button className="carousel-control-next" onClick={goToNext}>
          <span class="material-symbols-outlined">arrow_forward_ios
</span>
          </button>
        </div>
        <div className="sg-carousel-bottom-right-container">
          <SpecialNewsCard />
        </div>
      </div>
    </div>
  );
};

export default SgCarousel;